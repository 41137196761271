require('axios');

export default class Axios {
    constructor() {
        this.contentType = "application/json";
        this.dataType = "json";
        this.axios = axios;
    }

    get({ url }) {
        return this.axios.get(url);
    }
}
