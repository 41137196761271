import Axios from "../credit/Services/Axios";

$(document).ready(async function () {
    let lazyLoading = new LazyLoading();

    await lazyLoading.init();

    $(window).scroll(function () {
        lazyLoading.handle();
    });
});

class LazyLoading {
    async init()
    {
        this.load = true;
        this.axios = new Axios();

        axios.get('/general-text?key=premium_text').then(res => {
            this.premiumText = res.data.value;
        });

        axios.get('/interest-rate').then(res => {
            this.analysis_rate = res.data.rates.analysis_rate,
                this.insurance_rate = res.data.rates.insurance_rate,
                this.base_rate = res.data.rates.base_rate
        });

        this.checkLast();
    }

    calculateMinimumRate(analysis_rate, insurance_rate, base_rate, price) {
        const monthsNo = 60;

        let insuranceSum = (parseFloat(insurance_rate) / 100) * (parseFloat(price) + parseFloat(analysis_rate)) * monthsNo;
        let totalValue = parseFloat(price) + parseFloat(analysis_rate) + insuranceSum;

        let up = totalValue * (parseFloat(base_rate) / 100 / 12);

        let base = 1 + parseFloat(base_rate) / 100 / 12;
        let exp = -1 * monthsNo;

        let down = 1 - Math.pow(base, exp);

        return (up / down).toFixed(2);
    }

    handle() {
        // const test = Math.round(window.pageYOffset + window.innerHeight) - 200;

        // console.log($(document).height(), 'height');
        // console.log(Math.round(window.pageYOffset + window.innerHeight), 'round');

        if (this.load && Math.round(window.pageYOffset + window.innerHeight) >= $(document).height() - 50 / 100 * $(document).height()) {
            this.load = false;
            const lastId = $('.item').last().attr('id');

            const urlSearchParams = new URLSearchParams(window.location.search);
            const params = Object.fromEntries(urlSearchParams.entries());

            let url = `/pagination-items?first_element_id=${parseInt(lastId) + 1}`;

            if (window.location.pathname == '/') {
                url += '&collection=Homepage';
            }

            for (const [key, value] of Object.entries(params)) {
                url += `&${key}=${value}`
            }

            $('.loader').show();

            this.axios.get({url}).then(res => {
                $('.loader').remove();

                if (!res.data.next) {
                    this.load = false;
                } else {
                    this.load = true;
                }

                this.updateHtml(res.data);
            }).catch(error => {
                $('.loader').hide();
                this.load = false;
            });
        }
    }

    checkLast() {
        if ($('.grid-items .item').length < 24) {
            this.load = false;
        }
    }

    updateHtml({ cars }) {
        cars.forEach(car => {
            let gridContainer = $('.grid-items');
            let item = this.createElement('div', ['item'], car.id);
            $(gridContainer).append(item);

            let image = this.createElement('div', ['image']);
            $(item).append(image);

            let a = this.createElement('a', [], null, {
                href: `/cars/${car.slug}`
            });

            $(image).append(a);

            let preview = car.images[0].image.split('/');
            preview[1] = 'preview-' + preview[1];
            preview = preview.join('/');


            if (car.images.length) {
                let imgElement = this.createElement('img', ['max-w-full'], null,  {
                    src: `https://${window.location.hostname}/storage/${preview}`
                });
                $(imgElement).css('width', '100%');

                $(a).append(imgElement);
            }

            let label = this.createElement('label', ['label']);

            let divQuestion = this.createElement('div', ['question', 'question-btn']);
            $(divQuestion).append(`<svg width="18" height="18" fill="#fff"><use xlink:href="#question"></use></svg>`);
            let minimumRatePrice = car.discount > 0 ? car.discount : car.price;
            $(label).append(`${this.calculateMinimumRate(this.analysis_rate, this.insurance_rate, this.base_rate, minimumRatePrice)} ${car.currency === 'EURO' ? 'Euro' : 'RON'} /luna`);
            $(label).append(divQuestion);
            $(image).append(label);

            let answer = this.createElement('div', ['answer']);

            let heading = this.createElement('div', [
                'heading',
                'flex',
                'items-center',
                'justify-between'
            ]);

            // $(heading).append(`<h4 class="title-2 white">Selectie premium</h4>`);
            let closeAnswer = this.createElement('div', ['close-answer', 'cursor-pointer', 'close-btn']);
            $(closeAnswer).append(`<svg width="14" height="14" fill="#fff"><use xlink:href="#close-x"></use></svg>`);

            $(closeAnswer).on('click', function () {
                $(answer).slideUp(0);
            });

            $(heading).append(closeAnswer);
            $(answer).append(heading);
            $(answer).append(`<p class="text white">${this.premiumText}</p>`);

            //
            $(divQuestion).on('click', function () {
                $(answer).slideDown(0);
                $(answer).css({ 'display' : ''});
                $(answer).addClass('active-question');
            });

            $(image).append(answer);

            let description = this.createElement('div', ['description']);
            $(item).append(description);

            let left = this.createElement('div', ['left']);
            $(description).append(left);

            let h3 = this.createElement('h3');
            $(h3).append(`${car.maker.name} ${car.model.name}`);
            $(left).append(h3);

            let pDetails = this.createElement('p', ['mt-3'], null, {
                style: 'color:#e7e7e7'
            });

            let fuel_type = '';

            switch (car.fuel_type) {
                case 'diesel':
                    fuel_type = 'Diesel';
                    break;
                case 'gasoline':
                    fuel_type = 'Benzina';
                    break;
                case 'gasoline+gaz':
                    fuel_type = 'Benzina + motorina';
                    break;
                case 'gaz':
                    fuel_type = 'Gaz';
                    break;
                case 'electric':
                    fuel_type = 'Electric';
                    break;
            }

            let gear_box_type = '';

            switch (car.gear_box_type) {
                case 'manual':
                    gear_box_type = 'Manuala';
                    break;
                case 'automatic':
                    gear_box_type = 'Automatic';
                    break;
            }

            $(pDetails).append(`${car.year.split('-')[0]} - ${fuel_type} - ${car.cilindrical_capacity}cc - ${gear_box_type}`);
            $(left).append(pDetails);

            let price = this.createElement('div', ['price']);

            if (car.discount > 0 ) {
                $(price).append(`<strong class="discount-price-area" >${parseFloat(car.discount).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</strong>
                                <span class="discount-currency-area" >${car.currency == 'EURO' ? 'EUR' : 'RON'}</span>
                                <strong class="initial-price-area" >${parseFloat(car.price).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</strong>\t
                                <span class="initial-currency-area" >${car.currency == 'EURO' ? 'EUR' : 'RON'}</span>`)
            } else {
                $(price).append(`<strong class="discount-price-area">${parseFloat(car.price).toFixed(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}</strong>
                                 <span  class="discount-currency-area">${car.currency == 'EURO' ? 'EUR' : 'RON'}</span>`);
            }

            // console.log('price');

            $(left).append(price);

            let right = this.createElement('div', ['right']);
            $(description).append(right);

            let ancor = this.createElement(
                'a',
                [
                    'main-btn',
                    'default'
                ],
                null,
                {
                    href: `cars/${car.slug}`
                }
            );

            $(right).append(ancor);
            $(ancor).append(`<span>Informatii +</span>`);
        });

        $('.grid-items').append(`<div class="loader"></div>`);

        this.updateCarsNo(parseInt($('.count').text()) + cars.length);
    }

    createElement(type, classes, id, attributes) {
        const element = document.createElement(type);
        $(element).addClass(classes);
        $(element).attr('id', id);

        if (attributes) {
            for (const [attribute, value] of Object.entries(attributes)) {
                $(element).attr(attribute, value);
            }
        }

        return element;
    }

    updateCarsNo(carsNo) {
        $('.count').text(carsNo);
    }
}

const handle = function () {

}

const updateHtml = function ({ cars }) {

}

const createElement = function (type, classes, id, attributes) {

}

const updateCarsNo = function (carsNo) {
}
